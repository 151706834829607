import React, {useEffect, useState} from "react";
import {Edit2, CheckSquare, Crosshair} from "react-feather";
import SettingsForm from "./SettingsForm";
import API from "../../utils/API";
import {Badge} from "reactstrap";
import TokenRequirement from "../../components/fields/TokenRequirement";

const Settings = (props) => {
    const [edit,setEdit] = useState(false)
    const [data,setData] = useState(false)

    useEffect(()=>{
        if (props.id) {
            API.get('/admin/publisher/settings/' + props.id).then((response) => {
                setData(response.data.data)
            });
        }
    },[edit,props.id])

    return (
        <>
            {edit ?
                <>
                    <div className="row">
                        <div className="col">
                            <SettingsForm id={props.id} close={()=>setEdit(false)}/>
                        </div>
                        <div className="col-auto">
                            <button onClick={() => {setEdit(false)}} className="btn btn-outline-secondary mb-2">X</button>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="row">
                        <div className="col">
                            {data &&
                                <>
                                    <div className="row">
                                        <div className="col-auto">
                                            <strong>One To One Compliant? (3rd party leads)</strong>
                                        </div>
                                        <div className="col">
                                            {(parseInt(data.onetoone) === 1) ?
                                                <Badge color="success" className="mr-1 mb-1">
                                                    Yes
                                                </Badge>
                                                :
                                                <Badge color="secondary" className="mr-1 mb-1">
                                                    No
                                                </Badge>
                                            }

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <strong>Duplicate checker within a publisher</strong>
                                        </div>
                                        <div className="col">
                                            {(data?.duplicate && data?.duplicate * 1 > 0) ?
                                                <span className="font-weight-bold">{data?.duplicate} Days</span>
                                                :
                                                <Badge className="m-1" color={"secondary"}>Off</Badge>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <strong>Price reject variance</strong>
                                        </div>
                                        <div className="col">
                                            {(data?.pricerejectvariance && data?.pricerejectvariance * 1 > 0) ?
                                                <span className="font-weight-bold">{data?.pricerejectvariance}%</span>
                                                :
                                                <Badge className="m-1" color={"secondary"}>Off</Badge>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <strong>Token requirement</strong>
                                        </div>
                                        <div className="col">
                                            {(data?.tokenrequirements && data?.tokenrequirements?.length > 0) ?
                                                <span className="font-weight-bold"><TokenRequirement
                                                    requirement={data?.tokenrequirements}/></span>
                                                :
                                                <Badge color="danger" className="mr-1 mb-1">
                                                    Off
                                                </Badge>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <strong>Token requirement (<strong>PING</strong>)</strong>
                                        </div>
                                        <div className="col">
                                            {(data?.tokenrequirementsping && data?.tokenrequirementsping?.length > 0) ?
                                                <span className="font-weight-bold"><TokenRequirement
                                                    requirement={data?.tokenrequirementsping}/></span>
                                                :
                                                <Badge color="danger" className="mr-1 mb-1">
                                                    Off
                                                </Badge>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <strong>Flag or Fail Bad leads (3rd party traffic)</strong>
                                        </div>
                                        <div className="col">
                                            {(data?.failflagrule && data?.failflagrule === 'flag') ?
                                                <Badge color="secondary" className="mr-1 mb-1">
                                                    Flag
                                                </Badge>
                                                :
                                                <Badge color="danger" className="mr-1 mb-1">
                                                    Fail
                                                </Badge>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <strong>Copy failed leads to RGR</strong>
                                        </div>
                                        <div className="col">
                                            {(parseInt(data.copyfailedlead) === 1) ?
                                                <Badge color="success" className="mr-1 mb-1">
                                                    Yes
                                                </Badge>
                                                :
                                                <Badge color="secondary" className="mr-1 mb-1">
                                                    No
                                                </Badge>
                                            }

                                        </div>
                                    </div>
                                    {(data.creditreturn?.length > 0) &&
                                        <>
                                            <div className="row mt-2">
                                                <div className="col-auto">
                                                    <strong>Credit back to pub</strong>
                                                </div>
                                                <div className="col">
                                                    <CheckSquare color={"green"} size={18}/>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {((data.leadtype?.length > 0 && data.leadtype !== 'Select...') || data.sourcetraffic?.length > 0 || data.sourcepost?.length > 0 || data.sourcepingpost?.length > 0) &&
                                        <>
                                            <h4 className="mt-3">Traffic</h4>
                                            {(data.leadtype?.length > 0 && data.leadtype !== 'Select...') &&
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <strong>Lead type</strong>
                                                    </div>
                                                    <div className="col">
                                                        {data.leadtype}
                                                    </div>
                                                </div>
                                            }
                                            {data.sourcetraffic?.length > 0 &&
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <strong>Landing page</strong>
                                                    </div>
                                                    <div className="col">
                                                        <CheckSquare color={"green"} size={18}/>
                                                    </div>
                                                </div>
                                            }
                                            {data.sourcepost?.length > 0 &&
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <strong>Direct Post</strong>
                                                    </div>
                                                    <div className="col">
                                                        <CheckSquare color={"green"} size={18}/>
                                                    </div>
                                                </div>
                                            }
                                            {data.sourcepingpost?.length > 0 &&
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <strong>Ping/Post</strong>
                                                    </div>
                                                    <div className="col">
                                                        <CheckSquare color={"green"} size={18}/>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </div>
                        <div className="col-auto text-right">
                            <Edit2 onClick={() => {
                                setEdit(true)
                            }} className="align-middle mr-1 cursor-pointer" size={18}/>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Settings
